import { useEffect, useState } from "react";
import {
  FixedPrice,
  ICountry,
  ICustomer,
  IOperator,
  ITransaction,
  Product,
  RangedPrice,
} from "../../types/types";
import { EntityServices } from "../../Services/EntityServices";
import { END_POINTS_PATH, APP_PAGES } from "../../routes/paths";
import ShowSnackbar from "../../Services/snackbar";
import TopupForm from "./topupForm";
import TopupFormSummary from "./topupFormSummary";
import TopupFormActions from "./topupFormActions";
import { useNavigate } from "react-router-dom";

function Topup() {
  var navigator = useNavigate();
  const services = new EntityServices(navigator);

  const [formSteps, setFormSteps] = useState([
    "Topup Details",
    "Payment Summary",
  ]);

  const [currentStep, setCurrentStep] = useState(0);

  var _transactions = {} as ITransaction;

  const [transaction, setTransaction] = useState(_transactions);

  const [operators, setOperator] = useState([] as IOperator[]);
  const [OrgProducts, setOrgProduct] = useState([] as Product[]);
  const [products, setProduct] = useState([] as Product[]);
  const [productCategories, setProductCategory] = useState([] as string[]);
  const [countries, setCountry] = useState([] as ICountry[]);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(
    null as boolean | null
  );

  const [countriesLoading, setCountriesLoading] = useState(false);
  const [operatorsLoading, setOperatorsLoading] = useState(false);
  const [productsLoading, setProductsLoading] = useState(false);
  const [productCategoriesLoading, setProductCategoriesLoading] =
    useState(false);
  const [isValidPhoneNumberLoading, setIsValidPhoneNumberLoading] =
    useState(false);
  // state to control the next button
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

  const [isSubmiting, setIsSubmiting] = useState(false);

  const [error, SetError] = useState([
    { value: "" as String | Number, setError: () => {}, errorMessage: "" },
  ]);

  const [amountConfigLoading, setAmountConfigLoading] = useState(false);
  const [existedCustomerModel, setExistedCustomerModel] = useState(false);
  const [senderPhone, setSenderPhone] = useState("");
  // fixed price
  const [fixedPrice, setFixedPrice] = useState({} as FixedPrice);
  // ranged price
  const [rangedPrice, setRangedPrice] = useState({} as RangedPrice);

  const _requiredFields = [
    {
      name: "senderName",
      DisplayName: "Sender Name",
      value: transaction.senderName,
      errerMessage: "",
    },
    {
      name: "senderPhone",
      DisplayName: "Sender Phone",
      value: transaction.senderPhone,
      errerMessage: "",
    },
    {
      name: "countryISO2",
      DisplayName: "Country",
      value: transaction.countryISO2,
      errerMessage: "",
    },
    {
      name: "operatorId",
      DisplayName: "Operator",
      value: transaction.operatorId,
      errerMessage: "",
    },
    {
      name: "productCategory",
      DisplayName: "Product Category",
      value: transaction.productCategory,
      errerMessage: "",
    },
    {
      name: "productId",
      DisplayName: "Product",
      value: transaction.productId,
      errerMessage: "",
    },
    { name: "amount", value: transaction.amount, errerMessage: "" },
    {
      name: "recipientName",
      DisplayName: "Recipient Name",
      value: transaction.recipientName,
      errerMessage: "",
    },
    {
      name: "recipientPhone",
      DisplayName: "Recipient Phone",
      value: transaction.recipientPhone,
      errerMessage: "",
    },
  ];

  const [requiredFields, setRequiredFields] = useState(_requiredFields);

  useEffect(() => {
    GetCountries();
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setTransaction((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePaidAmountChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (e.target.value) {
      var paidAmount = parseFloat(e.target.value);

      var amount = paidAmount / transaction.exchangeRate;

      var points =
        amount.toString().split(".")[1]?.length > 3
          ? 3
          : amount.toString().split(".")[1]?.length;

      amount = parseFloat(amount.toFixed(points));

      (document.getElementById("amount") as HTMLInputElement).value =
        amount.toString();

      GetLocalAmountFromAmount(amount);

      setTransaction((prev) => ({
        ...prev,
        paidAmount: paidAmount,
        amount: amount,
      }));
    } else {
      setTransaction((prev) => ({
        ...prev,
        paidAmount: 0,
        amount: 0,
      }));
    }
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTransaction((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSenderPhoneBlur = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    const senderPhone = e.target.value;
    // Pass the sender phone number and show the existedCustomerModel
    setSenderPhone(senderPhone);
    showExistedCustomerModel(senderPhone);

    onBlur(e.target.name);
  };

  const showExistedCustomerModel = (senderPhone: string) => {
    // Logic to show the existedCustomerModel
    setExistedCustomerModel(true);
  };

  const handleLocalAmountChange = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    var localAmount = parseFloat(e.target.value);

    // using formula Two to calculate the amount
    var amount = GetAmountFromLocalAmount(localAmount);

    (document.getElementById("amount") as HTMLInputElement).value =
      amount.toString();

    configureAmountRates(amount);

    setTransaction((prev) => ({
      ...prev,
      localAmount: localAmount,
    }));

    onBlur(e.target.name);
  };

  const handleAmountChange = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    var amount = parseFloat(e.target.value);

    configureAmountRates(amount);

    // if the price type is range, calculate the local amount from the amount entered by the user using formula one
    if (transaction.priceType === "range") {
      GetLocalAmountFromAmount(amount);
    }

    onBlur(e.target.name);
  };

  const resetTransaction = (fieldName: string) => {
    if (fieldName === "countryISO2") {
      setOperator([] as IOperator[]);
      setProduct([] as Product[]);
      setProductCategory([] as string[]);
      setTransaction((prev) => ({
        ...prev,
        operatorId: "",
        operatorName: "",
        productCategory: "",
        productId: "",
        amount: 0,
        paidAmount: 0,
        localAmount: 0,
      }));
    } else if (fieldName === "operatorId") {
      setProduct([] as Product[]);
      setProductCategory([] as string[]);
      setTransaction((prev) => ({
        ...prev,
        productCategory: "",
        productId: "",
        amount: 0,
        paidAmount: 0,
        localAmount: 0,
      }));
    } else if (fieldName === "productCategory") {
      setProduct([] as Product[]);
      setTransaction((prev) => ({
        ...prev,
        productId: "",
        amount: 0,
        paidAmount: 0,
        localAmount: 0,
      }));
    } else if (fieldName === "productId") {
      setTransaction((prev) => ({
        ...prev,
        amount: 0,
        paidAmount: 0,
        localAmount: 0,
      }));
    }

    // if document.getElementById("amount") is not null, reset the value
    if (document.getElementById("amount") !== null) {
      (document.getElementById("amount") as HTMLInputElement).value = "0";
    }

    // if document.getElementById("localAmount") is not null, reset the value
    if (document.getElementById("localAmount") !== null) {
      (document.getElementById("localAmount") as HTMLInputElement).value = "0";
    }
  };

  const handleSelectChangeCountry = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    resetTransaction("countryISO2");

    if (e.target.value === "SLD") {
      GetOperators("SO");
    } else {
      GetOperators(e.target.value);
    }

    var country = countries.find((item) => item.Iso2 === e.target.value);

    setTransaction((prev) => ({
      ...prev,
      countryISO2: e.target.value,
      country: country?.Name ?? "",
    }));
  };

  const handleOperatorSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    resetTransaction("operatorId");

    GetOperatorProducts(e.target.value);

    var operator = operators.find((item) => item.Id === e.target.value);

    setTransaction((prev) => ({
      ...prev,
      operatorId: e.target.value,
      operatorName: operator?.Name ?? "",
      currency: operator?.Currency ?? "",
    }));
  };

  const handleSelectChangeProductCategory = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    resetTransaction("productCategory");

    GetProductCategories(e.target.value);

    setTransaction((prev) => ({
      ...prev,
      productCategory: e.target.value,
    }));
  };

  const handleSelectChangeProducts = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    resetTransaction("productId");

    var product = OrgProducts.find((item) => item.id === e.target.value);

    if (product === undefined) {
      return;
    }

    setTransaction((prev) => ({
      ...prev,
      priceType: product?.priceType ?? "",
      productName: product?.name ?? "",
    }));

    if (product?.priceType === "fixed") {
      var fixedPrice = product?.price as FixedPrice;
      setFixedPrice(fixedPrice);

      configureAmountRates(parseFloat(fixedPrice.user ?? "0"));

      var amount = parseFloat(fixedPrice.user ?? "0");

      configureAmountRates(amount);
    } else {
      var rangedPrice = product?.price as RangedPrice;
      setRangedPrice(rangedPrice);
      configureAmountRates(0);
    }

    setTransaction((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleOnBlurRecipeintNumber = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const re = /^[0-9\b]+$/;

    const prefixes = ["25262", "25265", "25266"];

    // check if the recipient phone number is empty or does not start with any of the prefixes when countryISO2 is SO
    if (e.target.value === "") {
      setIsValidPhoneNumber(false);
      setNextButtonDisabled(true);

      setRequiredFields((prev) => {
        return prev.map((item) => {
          if (item.name === "recipientPhone") {
            item.errerMessage =
              "Invalid recipient phone number format, Phone number must start with 25262, 25265 or 25266";
          }
          return item;
        });
      });

      return;
    }

    if (re.test(e.target.value)) {
      var isValid = await checkRecipientPhoneNumber(e.target.value);

      if (isValid === false) {
        setIsValidPhoneNumber(false);
        setNextButtonDisabled(true);
        return;
      }
      setIsValidPhoneNumber(true);
      onBlur(e.target.name);
    }
  };

  const GetCountries = async () => {
    try {
      setCountriesLoading(true);
      var response = await services.GetAllAsync(END_POINTS_PATH.GetCountries);

      if (response?.statusCode === 200) {
        var _countries = response.data.map((item: any) => {
          return {
            Name: item.name,
            Iso2: item.isO2,
          } as ICountry;
        });

        setCountry(_countries);
      }
      setCountriesLoading(false);

      configureAmountRates(0);
    } catch (error) {
      console.log("Failed to fetch countries");
    }
  };

  const GetOperators = async (country: string) => {
    setOperatorsLoading(true);
    try {
      var response = await services.GetDataByfilterAsync(
        END_POINTS_PATH.GetOperators + "/" + country
      );
      if (response?.statusCode == 200) {
        var data = JSON.parse(response.data);

        var _operators = data[country].map((item: any) => {
          return {
            Id: item.id,
            Name: item.name,
            Country: country,
            Currency: item.currency,
          } as IOperator;
        });

        setOperator(_operators);
      }
      setOperatorsLoading(false);
    } catch (error) {
      console.log("Failed to fetch operators", error);
    }
  };

  const GetOperatorProducts = async (operatorId: String) => {
    try {
      setProductsLoading(true);
      var response = await services.GetAllAsync(
        END_POINTS_PATH.GetOperatorProducts + "/" + operatorId
      );
      if (response?.statusCode === 200) {
        var data = JSON.parse(response.data);

        const categories = Object.keys(data);
        const productsByCategory = categories.map((category: any) => {
          return data[category].map((item: any) => {
            return {
              id: item.id,
              productType: item.productType,
              productCategory: item.productCategory,
              priceType: item.priceType,
              name: item.name,
              price: item.price,
            } as Product;
          });
        });

        var products = productsByCategory.flat();

        setOrgProduct(products);
        setProduct(products);
        setProductCategory(categories);
        setProductsLoading(false);
      }
    } catch (error) {
      console.log("Failed to fetch operator products");
    }
  };

  const toggleSaveAsShortcuts = () => {
    setTransaction((prev) => ({
      ...prev,
      saveAsShortcut: !prev.saveAsShortcut,
    }));
  };

  const GetProductCategories = async (category: string) => {
    try {
      setProductCategoriesLoading(true);
      var product = OrgProducts.filter(
        (item) => item.productCategory.name === category
      );

      setProduct(product);
      setProductCategoriesLoading(false);
    } catch (error) {
      console.log("Failed to fetch product categories");
    }
  };

  const checkRecipientPhoneNumber = async (phone: String): Promise<Boolean> => {
    try {
      setIsValidPhoneNumberLoading(true);
      var response = await services.GetDataByfilterAsync(
        END_POINTS_PATH.CheckRecipientPhoneNumber + "/" + phone
      );

      if (response === null) {
        return false;
      }

      var result = JSON.parse(response.data);

      setIsValidPhoneNumberLoading(false);
      return result.data.IsValid;
    } catch (error) {
      console.log("Failed to check phone number");
      return false;
    }
  };

  const configureAmountRates = async (amount: number) => {
    setAmountConfigLoading(true);

    try {
      var response = await services.GetSingle(
        END_POINTS_PATH.GetCurrentRate + "/GBP"
      );

      if (response == null) {
        return;
      }

      if (response.statusCode !== 200) {
        console.log("Failed to fetch response data: ", response.error);
        return;
      }

      var data;

      try {
        data = JSON.parse(response.data);
      } catch (parseError) {
        console.log("Failed to parse response data: ", parseError);
        return;
      }

      var rate = data as number;

      if (amount === undefined || amount === null || isNaN(amount)) {
        setTransaction((prev) => ({
          ...prev,
          exchangeRate: parseFloat(rate.toFixed(6)),
          paidAmount: 0,
          amount: 0,
        }));

        setAmountConfigLoading(false);
        return;
      }

      var paid = rate * amount;

      var points =
        paid.toString().split(".")[1]?.length > 3
          ? 3
          : paid.toString().split(".")[1]?.length;

      paid = parseFloat(paid.toFixed(points));

      setTransaction((prev) => ({
        ...prev,
        exchangeRate: parseFloat(rate.toFixed(6)),
        paidAmount: paid,
        amount: amount,
      }));

      setAmountConfigLoading(false);
    } catch (error) {
      console.log("Failed to fetch to configure amount: ", error);
    }
  };

  // Configure local amount using formula one
  const GetLocalAmountFromAmount = (amount: number) => {
    // formula one
    var localAmount =
      (amount * parseFloat(rangedPrice.max.operator ?? "0")) /
      parseFloat(rangedPrice.max.user ?? "0");

    var points =
      localAmount.toString().split(".")[1]?.length > 3
        ? 3
        : localAmount.toString().split(".")[1]?.length;

    localAmount = parseFloat(localAmount.toFixed(points));

    (document.getElementById("localAmount") as HTMLInputElement).value =
      localAmount.toString();

    setTransaction((prev) => ({
      ...prev,
      localAmount: localAmount,
    }));
  };

  // Get amount by calculating from local amount using formula two
  const GetAmountFromLocalAmount = (localAmount: number): number => {
    // formula two
    var amount =
      (localAmount * parseFloat(rangedPrice.max.user ?? "0")) /
      parseFloat(rangedPrice.max.operator ?? "0");

    var points =
      amount.toString().split(".")[1]?.length > 3
        ? 3
        : amount.toString().split(".")[1]?.length;

    amount = parseFloat(amount.toFixed(points));

    return amount;
  };

  const nextStep = () => {
    if (
      currentStep === formSteps.length - 1 ||
      currentStep > formSteps.length - 1 ||
      currentStep < 0
    ) {
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  const previousStep = () => {
    if (currentStep === 0) {
      return;
    }
    setCurrentStep(currentStep - 1);
  };

  const onBlur = (InputName: String) => {
    var field = requiredFields.find((field) => field.name === InputName);
    if (field) {
      setRequiredFields((prev) => {
        return prev.map((item) => {
          item.value = transaction[item.name as keyof ITransaction] as
            | string
            | number;
          if (item.name === InputName) {
            item.errerMessage =
              item.value === undefined || item.value === ""
                ? item.DisplayName + " is required"
                : "";
          }
          return item;
        });
      });
    }
  };

  useEffect(() => {
    checkIfFormIsValid();
  }, [requiredFields]);

  const checkIfFormIsValid = () => {
    const isValid = requiredFields.every((field) => {
      return field.value !== "" && field.value !== undefined;
    });

    setNextButtonDisabled(!isValid);
  };

  const setCustomer = (customer: ICustomer) => {
    setTransaction((prev) => ({
      ...prev,
      senderName: customer.fullname,
      senderPhone: customer.phone,
    }));
  };

  const submitTransaction = async () => {
    try {
      setIsSubmiting(true);
      var response = await services.Post(END_POINTS_PATH.Topup, transaction);

      if (response?.statusCode === 200) {
        ShowSnackbar("Transaction successful", "success");

        setTransaction(_transactions);

        setCurrentStep(0);
        window.location.href = APP_PAGES.Transactions;
      } else {
        ShowSnackbar(response?.error ?? "Transaction failed", "danger");
      }
      setIsSubmiting(false);
    } catch (error) {
      setIsSubmiting(false);
    }
  };

  return (
    <div className="hor-content main-content mt-0" style={{ zIndex: 400 }}>
      <div className="side-app">
        <div className="main-container  container">
          <div className="text-center my-6 border-bottom-2 bg-light-gray">
            <h4 className="fs-25">NEW TOPUP</h4>
            <p>Fill in the form below to topup the account</p>
          </div>
          {/* Topup Table */}
          <div className="row  bg-white">
            <div className="col-lg-12 col-md-12">
              <div className="card">
                <div className="card-body pb-0 mb-5 create-project-main">
                  <div className="row px-5 border-bottom">
                    <div className="d-flex flex-row">
                      {/* Form Steps */}
                      {formSteps.map((step, index) => {
                        // disable the next button if the current step is the last step
                        const isLastStep = currentStep === formSteps.length - 1;
                        return (
                          <span
                            key={index}
                            className={
                              "badge py-4 px-8 border " +
                              (currentStep === index
                                ? " bg-primary "
                                : " bg-default ") +
                              (isLastStep ? " disabled" : "")
                            }
                          >
                            {step}
                          </span>
                        );
                      })}
                    </div>
                  </div>

                  {/* Check if the currentStep of formsteps is equal recipient */}

                  {formSteps[currentStep].includes("Topup") ? (
                    /* Topup Form */
                    <>
                      <TopupForm
                        countries={countries}
                        operators={operators}
                        products={products}
                        productCategories={productCategories}
                        transaction={transaction}
                        countriesLoading={countriesLoading}
                        operatorsLoading={operatorsLoading}
                        productsLoading={productsLoading}
                        productCategoriesLoading={productCategoriesLoading}
                        isValidPhoneNumberLoading={isValidPhoneNumberLoading}
                        isValidPhoneNumber={isValidPhoneNumber}
                        existedCustomerModel={existedCustomerModel}
                        amountConfigLoading={amountConfigLoading}
                        handleChange={handleChange}
                        handleLocalAmountChange={handleLocalAmountChange}
                        handleAmountChange={handleAmountChange}
                        handleSelectChangeCountry={handleSelectChangeCountry}
                        handleSelectChangeOperator={handleOperatorSelectChange}
                        handleSenderPhoneBlur={handleSenderPhoneBlur}
                        handlePaidAmountChange={handlePaidAmountChange}
                        showExistedCustomerModel={showExistedCustomerModel}
                        handleSelectChangeProductCategory={
                          handleSelectChangeProductCategory
                        }
                        handleSelectChange={handleSelectChange}
                        handleSelectChangeProducts={handleSelectChangeProducts}
                        handleOnBlurRecipeintNumber={
                          handleOnBlurRecipeintNumber
                        }
                        setCustomer={setCustomer}
                        setTransaction={setTransaction}
                        senderPhone={senderPhone}
                        onBlur={onBlur}
                        requiredFields={requiredFields}
                      />
                    </>
                  ) : formSteps[currentStep].includes("Payment") ? (
                    /* Topup Summary */
                    <TopupFormSummary transaction={transaction} />
                  ) : null}

                  <TopupFormActions
                    transaction={transaction}
                    currentStep={currentStep}
                    formSteps={formSteps}
                    nextButtonDisabled={nextButtonDisabled}
                    isSubmiting={isSubmiting}
                    submitTransaction={submitTransaction}
                    nextStep={nextStep}
                    previousStep={previousStep}
                    toggleSaveAsShortcuts={toggleSaveAsShortcuts}
                    handleChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* End Topup Table */}
        </div>
      </div>
    </div>
  );
}

export default Topup;
